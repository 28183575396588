import React from "react";
import { graphql } from "gatsby";
import { ArticleNavSection } from "../components/article_nav";
import { CategoryFilterInfoSection } from "../components/intro/filter/category";
import SeoHelmet from "../components/seo";
import Layout from "../components/layout";

const SearchByCategory = ({ data, pageContext }) => {
  const searchResultInfo = {
    filterCondition: pageContext.ko,
    totalCount: data.allMdx.pageInfo.totalCount,
  };

  const articlesInfoProps = data.allMdx;

  const { skip, slug, ko, en } = pageContext;
  const pathname =
    skip === 0
      ? `/category/${slug}`
      : `/category/${slug}/page/${skip / 10 + 1}`;

  return (
    <>
      <SeoHelmet
        title={ko}
        description={`All ${searchResultInfo.totalCount} articles on the ${en} category.`}
        pathname={pathname}
      />
      <Layout>
        <CategoryFilterInfoSection searchResultInfo={searchResultInfo} />
        <ArticleNavSection articlesInfoProps={articlesInfoProps} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($slug: String!, $skip: Int!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: $slug } } }
      limit: 10
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          description
          date(formatString: "MMMM D, YYYY")
          category
          tags
          hero_image {
            childImageSharp {
              gatsbyImageData(width: 900, aspectRatio: 2)
            }
          }
          hero_image_alt
        }
        id
        slug
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        totalCount
      }
    }
  }
`;

export default SearchByCategory;
